.privacy-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

h1, h2, h3, h4 {
    color: #333;
}

h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

h2 {
    font-size: 2em;
    margin-top: 30px;
    margin-bottom: 15px;
}

h3 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    line-height: 1.6;
    margin-bottom: 15px;
}

ul {
    margin-bottom: 15px;
    padding-left: 20px;
}

li {
    margin-bottom: 10px;
}

a {
    color: #0066cc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
