/* Scan.css */

/* Container styles */
.scan-container {
  width: 100%; 
  height: 140%;/* Ensure the container takes up the full width */
  display: flex;
  justify-content: center; /* Center the image horizontally */
  padding: 1rem; /* Add padding around the container */
  box-sizing: border-box; /* Include padding in the container's total width */
}

/* Image styles */
.full-width-image {
  width: 100%; /* Make the image fill the width of its container */
  height: auto; /* Maintain the aspect ratio of the image */
  max-width: 1200px; /* Set a maximum width for larger screens */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .scan-container {
    padding: 0.5rem; /* Adjust padding for smaller screens */
  }

  .full-width-image {
    width: 90%; /* Make the image slightly smaller on mobile */
    max-width: 100%; /* Ensure image scales properly */
  }
}
