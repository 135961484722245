.terms-container {
    margin: 40px auto;
    padding: 20px;
    max-width: 800px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .terms-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .terms-container h2 {
    font-size: 1.8em;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .terms-container h3 {
    font-size: 1.4em;
    margin-top: 20px;
    color: #34495e;
  }
  
  .terms-container p {
    font-size: 1em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }
  
  .terms-container ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .terms-container ul li {
    margin-bottom: 10px;
  }
  
  .terms-container a {
    color: #3498db;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  