.servicesSection {
  text-align: center;
  padding: 40px 0px;
  width: 100%;
  /* border: 1px solid ; */
 
  background-color: #f9f9f9;
  position: relative; /* Ensure circles are positioned relative to this section */
  overflow: hidden; /* Prevent circles from overflowing */
}

.servicesHeader {
  margin-bottom: 40px;
  position: relative; 
    z-index: 1;
   }

.ourServices {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 0.5rem;
  width: 100%;
}

.servicesDescription {
  font-family: 'Roboto Slab', serif; /* Use the niche font */
  font-weight: bold; /* Make the text bold */
  margin: 20px 0; /* Top and bottom margin of 20px */
  padding: 10px; /* Padding of 10px on all sides */
  line-height: 1.6; /* Line height for readability */
  color: #333; /* Dark gray text color for better readability */
  text-align: center; /* Center align the text */
    width: 94%;
}
.knowMoreButton {
  font-size: 1rem;
  color: #007bff;
  border: 2px solid #007bff;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.knowMoreButton:hover {
  background-color: #007bff;
  color: #fff;
}

.servicesGrids {
  display: flex; 
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2px 2px 2px 2px;
  /* border: 1px solid ; */
  width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease;
  align-items: center;
}


.serviceCard:hover {
  transform: scale(1.05);
}

.serviceIcon {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.servicecard {
  width: 180px;
  height: 240px;
  margin-bottom: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease; /* Add transition for smooth effect */
}

.servicecard:hover {
  transform: scale(1.05);
  background-color: #f0f0f0; /* Optional: change background color on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Add shadow on hover */
}


.serviceTitle {
  font-size: 1.1rem;
  color: #333;
  width: 100%;
}

/* Background circles */
.backgroundCircle1, .backgroundCircle2 {
  position: absolute;
  border-radius: 50%;
  z-index: -1; /* Place behind other content */
}

.backgroundCircle1 {
  width: 300px;
  height: 300px;
  background: linear-gradient(circle, #C4F2FF 0%, #C7DDFF 100%);
  top: 10%; /* Adjust positioning as needed */
  left: -50px; /* Adjust positioning as needed */
  opacity: 0.5;
}

.backgroundCircle2 {
  width: 400px;
  height: 400px;
  background: linear-gradient(circle, #FFDED2 0%, #C7DDFF 100%);
  bottom: -50px; /* Adjust positioning as needed */
  right: -50px; /* Adjust positioning as needed */
  opacity: 0.5;
}


