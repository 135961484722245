/* General Styles */
.heroContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.heroParagraphsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
}

.heroParagraphs {
  max-width: 600px;
}

.sitBackAnd {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.worriedWithCar {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.connectWithUsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.connectWithUsButton {
  font-size: 1rem;
  color: #000;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #000;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.connectWithUsButton:hover {
  background-color: #000;
  color: #fff;
}

.arrowIcon {
  margin-left: 10px;
  font-size: 1.2rem;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}



/* Responsive Design */
@media screen and (max-width: 1200px) {
  .sitBackAnd {
    font-size: 2.5rem;
  }

  

}


@media screen and (max-width: 576px) {
  .sitBackAnd {
    font-size: 1.5rem;
  }

  .heroParagraphsWrapper {
    flex-direction: column;
    align-items: center;
  }

  .heroParagraphs {
    text-align: center;
  }


}

/* Mobile-specific styles */
@media screen and (max-width: 576px) {
  .heroContentWrapper {
    padding: 10px;
  }

  .heroContent {
    align-items: center;
  }

  .sitBackAnd {
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.2;
  }

  .worriedWithCar {
    font-size: 0.875rem;
    text-align: center;
  }

  .connectWithUsButton {
    font-size: 0.875rem;
    padding: 8px 16px;
  }

  .arrowIcon {
    font-size: 1rem;
  }

 

  .heroParagraphsWrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .heroParagraphs {
    max-width: 100%;
    text-align: center;
  }
}
