.landingPageChild,
.minCarServiceContentChild {
  position: absolute;
  margin: 0 !important;
  border-radius: 50%;
  transform: rotate(-71.8deg);
  transform-origin: 0 0;
}
.landingPageChild {
  width: 306px;
  height: 306px;
  top: 1391.66px;
  right: -20px;
  background: linear-gradient(180deg, #c7ddff, #fff);
}
.minCarServiceContentChild {
  height: calc(100% - 171.2px);
  width: 652px;
  top: 619.31px;
  bottom: -448.11px;
  left: -23px;
  background: linear-gradient(
    180deg,
    rgba(196, 243, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  );
}
.minCarService {
  width: 317px;
  position: relative;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}
.minCarServiceContent {
  height: 823.2px;
  width: 800.2px;
  margin: 0 !important;
  position: absolute;
  top: -361px;
  right: -411.2px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 198px var(--padding-37xl) 198.2px;
  box-sizing: border-box;
}
.frameChild {
  height: 258.5px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 2;
}
.minCarServiceContentParent {
  width: 375px;
  margin: 0 !important;
  position: absolute;
  top: 1551px;
  left: 14px;
  flex-direction: row;
  max-width: 100%;
}
.carWashCard,
.minCarServiceContentParent,
.servicesGrid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.carWashCard {
  width: 2306.7px;
  flex-shrink: 0;
  flex-direction: column;
  gap: 17.3px;
  max-width: 189%;
}
.servicesGrid {
  width: 1280px;
  overflow-x: auto;
  flex-direction: row;
  padding: 0 var(--padding-10xl) 51.3px;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
}
.background {
  align-self: stretch;
  height: 594px;
  position: relative;
  background-color: var(--white);
  display: none;
}
.aboutUs {
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
  min-width: 65px;
}
.aboutUsLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
}
.discover,
.explore {
  position: relative;
  display: inline-block;
  min-width: 73px;
}
.explore {
  min-width: 64px;
}
.links,
.linksWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.links {
  align-self: stretch;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  gap: 70px;
  z-index: 1;
}
.linksWrapper {
  width: 468px;
  flex-direction: column;
  padding: var(--padding-10xs) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.facebookIcon,
.twitterIcon {
  width: 24px;
  position: relative;
  z-index: 1;
}
.facebookIcon {
  height: 24px;
  min-height: 24px;
}
.twitterIcon {
  height: 20px;
}
.socialIcons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-11xs);
}
.vimeoIcon {
  width: 24px;
  height: 21px;
  position: relative;
  z-index: 1;
}
.socialIcons1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-12xs);
}
.youtubeIcon {
  width: 24px;
  height: 17px;
  position: relative;
  z-index: 1;
}
.socialIcons2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 var(--padding-10xs);
}
.socialLinks {
  width: 216px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.footerLinksSocial,
.linksContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.linksContainer {
  width: 1074px;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.footerLinksSocial {
  align-self: stretch;
  justify-content: center;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  flex-shrink: 0;
}
.footer15LiftBlue,
.frameParent,
.landingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}
.footer15LiftBlue {
  margin-left: -122px;
  width: 1440px;
  height: 595px;
  background-color: var(--white);
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-start;
  padding: 357px 0 82.1px;
  gap: 48px;
  max-width: 121%;
  margin-top: -58px;
}
.frameParent,
.landingPage {
  text-align: left;
}
.frameParent {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 42px;
  max-width: 100%;
  font-size: var(--body-regular-r-3-size);
  color: var(--dark);
  font-family: var(--font-helvetica-neue);
}
.landingPage {
  width: 100%;
  position: relative;
  background-color: var(--white);
  overflow: hidden;
  align-items: flex-end;
  padding: 0 0 var(--padding-3xs);
  gap: 34.7px;
  line-height: normal;
  letter-spacing: normal;
  font-size: var(--font-size-5xl);
  color: var(--color-black);
  font-family: var(--font-poppins);
}
@media screen and (max-width: 1275px) {
  .frameParent {
    padding-left: var(--padding-2xl);
    padding-right: var(--padding-2xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1100px) {
  .servicesGrid {
    padding-bottom: var(--padding-14xl);
    box-sizing: border-box;
  }
  .linksContainer {
    flex-wrap: wrap;
  }
  .footer15LiftBlue {
    height: auto;
    padding-bottom: 53px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .footer15LiftBlue,
  .servicesGrid {
    padding-bottom: var(--padding-2xl);
    box-sizing: border-box;
  }
  .footer15LiftBlue {
    gap: var(--gap-5xl);
    padding-bottom: var(--padding-15xl);
  }
  .landingPage {
    gap: var(--gap-mid);
  }
}
@media screen and (max-width: 450px) {
  .minCarService {
    font-size: var(--font-size-lgi);
  }
  .links {
    gap: var(--gap-16xl);
  }
}
html {
  scroll-behavior: smooth;
}

.section {
  padding: 60px 0; /* Adjust based on your design */
 } /* Add other styling as needed */

 .lan {
  position: relative;
  padding: 20px; /* Adjust based on your design */
}

