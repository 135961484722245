/* General Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  background-color: #0483f3;
}

.logoContainer {
  display: flex;
  align-items: center;
  color: white;
  ;
}

.logo {
  max-height: 90px;
  color: white;
}

.navItemsContainer {
  display: flex;
  color: white;
  gap: 20px;
}

.link {
  color: white;
}

.navItem {
  padding: 10px 15px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.navItem:hover {
  background-color: #0483f3;
  color: white;
}

/* Mobile-specific styles */
.mobileMenuButton {
  display: none;
  background-color: #0483f3;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .navItemsContainer {
    display: none;
    flex-direction: column;
    background-color: #0483f3;
    position: absolute;
    top: 60px;
    right: 20px;
    border-radius: 5px;
    padding: 10px;
    z-index: 1000; /* Ensure the menu appears on top of other content */
    width: 200px; /* Adjust width as needed */
  }

  .navItemsContainer.open {
    display: flex;
  }

  .mobileMenuButton {
    display: block;
  }

  .navItem {
    color: white;
    background-color: #0483f3;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ffffff;
    width: 90%; /* Make sure nav items take up full width */
  }

  .navItem:last-child {
    border-bottom: none;
  }
}
