.leadership-team {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.leadership-title {
    font-size: 24px;
    margin-bottom: 10px;
    position: relative;
}

.leadership-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: #007bff;
    margin: 8px auto;
}

.leadership-description {
    font-size: 16px;
    color: black;
    margin-bottom: 20px;
}

.team-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    color: white;
}

.box {
    width: 200px;
    height: 250px;
    background-color: #E6F7FF; /* Light blue background */
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.box:hover {
    transform: translateY(-10px);
}

.box img {
    width: 200px; /* Adjust based on the size of your image */
    height: 200px;
    /* border-radius: 50%; To make the image circular */
    object-fit: cover;
}
